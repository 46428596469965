export const chunbuDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/唇部素材/产品模特图/KH1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/KH2.jpg'),
		series: '唇部',
		seriesName: '水波口红',
		zhName: '酵色「水波口红」',
		enName: 'Sand Ripple Matte Lip Rouge',
		intro: '柔雾哑光质地 奶油感红唇',
		price: '79',
		of: '3.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/水波口红素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/水波口红素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/水波口红素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/水波口红素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/水波口红素材/大图/1.jpg') },
		],
		color: [
			{
				active: '#823d3f',
				name: '#101 瓦砾回声',
				span1: '灰调陶土奶咖 丧甜超模',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/01.jpg')
			},
			{
				active: '#a2514d',
				name: '#102 赤茶冥想',
				span1: '土调红棕 慵懒气质',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/02.jpg')
			},
			{
				active: '#be4347',
				name: '#103 杏桃冻干',
				span1: '粉雾感杏桃 甜美清爽',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/03.jpg')

			},
			{
				active: '#a33e4a',
				name: '#104 暮色花市',
				span1: '烟熏紫豆沙 轻熟迷人',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/04.jpg')

			},
			{
				active: '#994c38',
				name: '#105 日落苏打',
				span1: '复古黄棕调 油画质感',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/05.jpg')

			},
			{
				active: '#cc2347',
				name: '#106 羞怯甜莓',
				span1: '粉紫莓果 冷调迷情',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/06.jpg')

			},
			{
				active: '#7e0920',
				name: '#107 沉溺夜月',
				span1: '蓝调浆果 叛逆俏皮',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/07.jpg')

			},
			{
				active: '#aa2929',
				name: '#108 持有天真',
				span1: '艳阳苹果红 活力满满',
				span2: ' ',
				color: require('@/assets/img/详情/水波口红素材/大图/08.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/唇部素材/产品模特图/SCCY1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/SCCY2.jpg'),
		series: '唇部',
		seriesName: '丝绸唇釉',
		intro: '不沾杯唇泥质地  持久柔雾妆效',
		zhName: '酵色「丝绸唇釉」',
		enName: 'Silky Silk Matte Lipgloss',
		price: '79',
		of: '3.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/大图/4.jpg') },
		],
		color: [
			{
				active: '#b16750',
				name: '#M01 不眠岛屿',
				span1: '土调杏色 干净高级',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/01.jpg')
			},
			{
				active: '#c36356',
				name: '#M02 迷路烟杏',
				span1: '蜜桃奶杏色 素颜轻松驾驭',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/02.jpg')
			},
			{
				active: '#b1464d',
				name: '#M03 心碎豆沙',
				span1: '冷烟粉豆沙 软糯奶糖少女',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/03.jpg')
			},
			{
				active: '#a52310',
				name: '#M04 红釉碎片',
				span1: '热辣血橙色 时髦气场',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/04.jpg')
			},
			{
				active: '#6f3324',
				name: '#M05 土星光环',
				span1: '栗子牛奶 个性酷感',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/05.jpg')
			},
			{
				active: '#d83523',
				name: '#M06 流浪悬日',
				span1: '热辣血橙色 亮眼霓虹',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/06.jpg')
			},
			{
				active: '#da353c',
				name: '#M07 失重春天',
				span1: '草莓甜心 元气少女',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/07.jpg')
			},
			{
				active: '#8a347e',
				name: '#M08 霓虹巴士',
				span1: '特调玫瑰紫薯  不显黑的“口紫”',
				span2: ' ',
				color: require('@/assets/img/详情/丝绸素材/大图/08.jpg')
			},
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/唇部素材/产品模特图/BKCY1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/BKCY2.jpg'),
		series: '唇部',
		seriesName: '贝壳镜面唇釉',
		zhName: '酵色「贝壳镜面唇釉」',
		enName: 'Spiral Shell Glazed Lipgloss',
		price: '79',
		of: '3.2g',
		intro: '轻薄水感 锁住镜面玻璃唇',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#942825',
				name: '#01 杨枝甘露',
				span1: '纯欲橘调  可盐可甜',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/01.jpg')
			},
			{
				active: '#851726',
				name: '#02 葡萄冻冻',
				span1: '葡萄细闪 明媚气质',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/02.jpg')
			},
			{
				active: '#a5212b',
				name: '#04 草莓汽酒',
				span1: '软糯草莓 俏皮活力',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/04.jpg')
			},
			{
				active: '#8a2214',
				name: '#06 脏脏奶咖',
				span1: '脏脏茶棕 复古百搭',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/06.jpg')
			},
			{
				active: '#9b3a3d',
				name: '#07 鸳鸯奶茶',
				span1: '清爽豆沙 纯欲轻熟',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/07.jpg')
			},
			{
				active: '#8f3529',
				name: '#08 焦糖奶茶',
				span1: '焦糖橘棕 日杂氛围',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/08.jpg')
			},
			{
				active: '#bd4851',
				name: '#09 桃心水母',
				span1: '肉桂蜜粉 甜美丰盈',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/09.jpg')
			},
			{
				active: '#be5957',
				name: '#10 海盐奶咖',
				span1: '裸感奶茶色 显色温柔',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/10.jpg')
			},
			{
				active: '#b7364e',
				name: '#11 半熟葡萄',
				span1: '果味十足 轻熟妩媚',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/11.jpg')
			},
			{
				active: '#ad3e48',
				name: '#12 柿子茶冻',
				span1: '红茶果冻 半熟少女',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/12.jpg')
			},
			{
				active: '#84141c',
				name: '#31 逃离银河',
				span1: '浓郁血浆色 气场显白',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/唇釉/大图/13.jpg')
			},
		]
	},
	{
		id: 4,
		defalut: require('@/assets/img/唇部素材/产品模特图/HPCY1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/HPCY2.jpg'),
		series: '唇部',
		seriesName: '琥珀唇釉',
		zhName: '酵色「琥珀唇釉」',
		enName: 'Tortoise Shell Fluffy Matte Lipgloss',
		price: '79',
		of: '3.3g',
		intro: '浓郁色泽 棉柔乳霜质感',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#a9424b',
				name: '#V01 粉褐白桃',
				span1: '温柔豆沙色 明媚动人',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/01.jpg')
			},
			{
				active: '#8e2828',
				name: '#V02 深海珊瑚 ',
				span1: '冷调棕红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/02.jpg')

			},
			{
				active: '#7f161c',
				name: '#V03 酒渍血珀',
				span1: '明艳苹果红 元气女团色',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/03.jpg')
			},
			{
				active: '#902c41',
				name: '#V04 微醺芋泥',
				span1: '烟熏梅子色 高冷气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/04.jpg')
			},
			{
				active: '#84443c',
				name: '#V05 杏仁肉桂',
				span1: '杏仁肉桂色 纯欲氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/05.jpg')

			},
			{
				active: '#932017',
				name: '#V06 枫糖番茄',
				span1: '棕调烂番茄 复古显白',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/06.jpg')
			},
			{
				active: '#78241f',
				name: '#V07 琥珀糖浆',
				span1: '质感脏橘 迷人风情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/07.jpg')
			},
			{
				active: '#822529',
				name: '#V08 糖霜枣泥',
				span1: '浓郁泥枣红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/08.jpg')
			},
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/10.jpg')
			},
			{
				active: '#a42316',
				name: '#V11 坠落花火',
				span1: '暖调红梨色 南法热情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/11.jpg')
			},
			{
				active: '#b0523e',
				name: '#V12 迷雾陶土',
				span1: '奶杏土橘色 温柔纯欲',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/12.jpg')
			},
			{
				active: '#ac3d3a',
				name: '#V13 半醉桃气',
				span1: '玫瑰蜜桃调 软糯少女',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/13.jpg')
			},
		]
	},
	{
		id: 5,
		defalut: require('@/assets/img/唇部素材/产品模特图/Y2KCY1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/Y2KCY2.jpg'),
		series: '唇部',
		seriesName: 'Y2K唇釉',
		zhName: '酵色「Y2K唇釉」',
		enName: 'Future Nostalgia Matte Lipgloss',
		price: '79',
		of: '3.2g',
		intro: '跳跃亮色 辣妹必备',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/Y2K素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/Y2K素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/Y2K素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/Y2K素材/大图/1.jpg') },
		],
		color: [
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/Y2K素材/大图/10.jpg')
			},
		]
	},
	{
		id: 6,
		defalut: require('@/assets/img/唇部素材/产品模特图/CY1.jpg'),
		active: require('@/assets/img/唇部素材/产品模特图/CY2.jpg'),
		series: '唇部',
		seriesName: 'Y2K唇釉',
		zhName: '酵色「护唇油」',
		enName: 'Winter Sweet Lip Oil',
		price: '79',
		of: '2.5g',
		intro: '护唇显色二合一 享受无负担嘟嘟唇',

		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/唇油素材/小图/4.jpg'), bigUrl: require('@/assets/img/详情/唇油素材/大图/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/唇油素材/小图/2.jpg'), bigUrl: require('@/assets/img/详情/唇油素材/大图/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/唇油素材/小图/3.jpg'), bigUrl: require('@/assets/img/详情/唇油素材/大图/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/唇油素材/小图/1.jpg'), bigUrl: require('@/assets/img/详情/唇油素材/大图/01.jpg') },
		],
		color: [
			{
				active: '#e2d4c6',
				name: '#01 咖啡',
				span1: '夜间咖啡 深度修护',
				span2: ' ',
				color: require('@/assets/img/详情/唇油素材/大图/1.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/唇油素材/大图/2.jpg')
			},
		]
	}
]
